


















































































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Car } from '@/store/types';
import store from '@/store';
import { AppliedDiscount, makeReservation } from '@/services/reservations.service';
import { AlertCircleIcon, ChevronLeftIcon } from 'vue-feather-icons';

import SummaryBox from '@/components/checkout/summary/SummaryBox.vue';
import Heading from '@/components/checkout/layout/page/Heading.vue';
import TextField from '@/components/ui/form/TextField.vue';
import CheckboxField from '@/components/ui/form/CheckboxField.vue';
import Button from '@/components/ui/Button.vue';
import Alert from '@/components/ui/Alert.vue';
import RadioField from '@/components/ui/form/RadioField.vue';
import { ConditionsRoute, PaymentRoute, PriceListRoute, PrivacyPolicyRoute } from '@/router/routes';
import { Location, NavigationGuardNext, Route } from 'vue-router';
import CheckoutStepThreeFormMixin from '@/components/screens/checkout/CheckoutStepThreeForm.mixin';
import additionalServicesService, { AdditionalService } from '../../../services/additionalService.service';
import { PaymentInstrument } from '@/models/paymentInstrument';
import { StripePaymentError, StripeMixin } from '@/mixins/stripe';
import { AuthMixin } from '@/mixins/user/Auth.mixin';
import PaymentMethods from '@/components/user/payment/PaymentMethods.vue';
import { GtagMixin } from '@/mixins/googleAnalytics/gtag.mixin';
import { RoutingMixin } from './Routing.mixin';
import featureFlagService, { FeatureFlag } from '@/services/featureFlag.service';

@Component({
  components: {
    PaymentMethods,
    RadioField,
    Alert,
    Button,
    CheckboxField,
    TextField,
    Heading,
    SummaryBox,
    ChevronLeftIcon,
    AlertCircleIcon
  }
})
export default class CheckoutStepThree extends Mixins(
  CheckoutStepThreeFormMixin,
  StripeMixin,
  AuthMixin,
  GtagMixin,
  RoutingMixin
) {
  @Prop() readonly car: Car | undefined;
  private additionalServices: AdditionalService[] = [];

  private loadingText = '';
  private loading = false;
  private selectedPaymentInstrumentId: string | undefined = '';
  public bankTransferEnabled = false;
  public additionalServicesEnabled = false;
  public isReservationProcessBlocked = false;
  public reservationProcessBlockedText = 'Omlouváme se, právě probíhá plánovaná odstávka. Zkuste to prosím později.';

  get queryFrom(): string {
    return this.$route.query.from.toString() ?? '';
  }

  get queryTo(): string {
    return this.$route.query.to.toString() ?? '';
  }

  get termsAndConditionsLink(): Location {
    return { name: ConditionsRoute };
  }

  get privacyPolicyLink(): Location {
    return { name: PrivacyPolicyRoute };
  }

  get priceListLink(): Location {
    return { name: PriceListRoute };
  }

  get isSubscribedToNewsletter(): boolean {
    return store.state.subscribesToNewsletter;
  }

  toggleDisabledActive() {
    store.dispatch('toggleReservationCheckoutLoading');
  }
  get activeDiscount(): AppliedDiscount | null {
    return store.state.activeDiscount;
  }

  get documentsValid() {
    return store.state.documentsValid;
  }

  handleAdditionalServicesInput(serviceId: string) {
    if (this.values.additionalServices.has(serviceId)) {
      this.values.additionalServices.delete(serviceId);
    } else {
      this.values.additionalServices.add(serviceId);
    }
  }

  handleSelectedPaymentInstrument(paymentInstrument: PaymentInstrument | null) {
    this.selectedPaymentInstrumentId = paymentInstrument?.paymentInstrumentData?.externalId;
  }

  async mounted() {
    // TODO Investigate this, user should be always logged in this state
    if (this.loggedUser !== undefined) {
      store.dispatch('setNewsletterStatus');
    }
    this.additionalServicesEnabled = await featureFlagService.getFeatureFlagActiveByName(
      FeatureFlag.ADDITIONAL_SERVICES_ENABLED
    );
    if (this.additionalServicesEnabled) {
      this.additionalServices = await additionalServicesService.getAdditionalServices();
    }
    window.addEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
    if (this.$store.state.activeDiscount !== null) {
      this.$store.dispatch('setActiveDiscount', null);
    }
    [this.bankTransferEnabled, this.isReservationProcessBlocked] = await Promise.all([
      featureFlagService.getFeatureFlagActiveByName(FeatureFlag.BANK_TRANSFER_ENABLED),
      featureFlagService.getFeatureFlagActiveByName(FeatureFlag.IS_RESERVATION_PROCESS_BLOCKED)
    ]);
  }

  goBack(): void {
    this.$emit('changeActiveStep', 2);
  }

  private loadingTexts = ['Ověřujeme dostupnost auta…', 'Kontrolujeme nahrané doklady…', 'Vytváříme rezervaci…'];

  i = 0;
  timeoutID!: ReturnType<typeof setTimeout>;
  loadingTextInterval(): void {
    this.loadingText = this.loadingTexts[this.i];
    this.i = (this.i + 1) % this.loadingTexts.length;
    this.timeoutID = setTimeout(this.loadingTextInterval, 2000);
  }

  beforeUnloadListener = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = 'Opravdu chcete odejít? Vaše objednávka tím bude zrušena.');
  };

  async submit(): Promise<void> {
    if (!this.validate() || !this.selectedPaymentInstrumentId) {
      return;
    }

    window.addEventListener('beforeunload', this.beforeUnloadListener, { capture: true });

    let reservation;
    try {
      this.loading = true;
      this.toggleDisabledActive();
      this.loadingTextInterval();

      this.$router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
        if (this.loading) {
          confirm('Prosím vyčkejte na dokončení objednávky. Vaše objednávka jinak bude zrušena.');
          next(false);
          return false;
        } else {
          next();
        }
      });
      reservation = await makeReservation({
        carId: this.car?._id,
        paymentMethod: this.values.paymentMethod,
        start: this.queryFrom,
        end: this.queryTo,
        subscribesToNewsletter: this.values.subscribesToNewsletter,
        additionalServices: Array.from(this.values.additionalServices.values()),
        paymentInstrumentExternalId: this.selectedPaymentInstrumentId,
        discountCode: this.activeDiscount ? this.activeDiscount.code : undefined
      });

      this.$fbq('track', 'Purchase', {
        value: reservation.overallPrice - (this.activeDiscount ? this.activeDiscount.priceDiscountedByAmount : 0),
        currency: 'CZK'
      });
      this.gtagConsoleLogsEnabled &&
        console.log(
          `FBQ: Value: ${
            reservation.overallPrice - (this.activeDiscount ? this.activeDiscount.priceDiscountedByAmount : 0)
          } CZK`
        );

      if (this.$gtm && this.gtag && this.$gtm.enabled()) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'reservationTracking',
          reservation
        });
        this.gtag.event('conversion', {
          value: reservation.overallPrice - (this.activeDiscount ? this.activeDiscount.priceDiscountedByAmount : 0),
          currency: 'CZK'
        });
        this.gtagConsoleLogsEnabled &&
          console.table(
            `GTAG: Name: conversion, Value: ${
              reservation.overallPrice - (this.activeDiscount ? this.activeDiscount.priceDiscountedByAmount : 0)
            } CZK`
          );

        this.gtag.event('purchase', {
          transaction_id: reservation.id,
          value: reservation.overallPrice - (this.activeDiscount ? this.activeDiscount.priceDiscountedByAmount : 0),
          currency: 'CZK',
          // TODO Investigate this, user should be always logged in this state
          ...(this.loggedUser?.email ? { email: this.loggedUser?.email } : {})
        });
        this.gtagConsoleLogsEnabled &&
          console.log(
            `GTAG: Name: purchase, ${
              this.loggedUser?.email ? `email: ${this.loggedUser?.email} ` : ''
            }transaction_id: ${reservation.id}, value: ${
              reservation.overallPrice - (this.activeDiscount ? this.activeDiscount.priceDiscountedByAmount : 0)
            } CZK `
          );

        this.$gtm.trackEvent({
          event: 'reservationCompleted'
        });
        this.gtagConsoleLogsEnabled && console.log('GTM: event: reservationCompleted');
      } else
        this.gtagConsoleLogsEnabled &&
          console.error(
            'Condition not met: this.$gtm && this.gtag && this.$gtm.enabled(), therefore no gtag console logs can be seen here.'
          );
    } catch (error) {
      console.error(error);
      this.loading = false;
      const err: Error = error as Error;
      if (err.message === 'Datum začátku ani konce rezervace nemůže být v minulosti, vyberte prosím nový termín.') {
        this.$toast.error(err.message);
        this.resetReservationDatesInState();
        return this.navigateToRental();
      } else if (err.message === 'Auto bohužel není ve vybraném termínu dostupné, mrkněte se na naše ostatní auta.') {
        this.$toast.error(err.message);
        return this.navigateToRental();
      } else {
        this.$toast.error('Při vytváření rezervace se stala neočekáváná chyba, zkuste opakovat akci později.');
      }
      window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
      this.toggleDisabledActive();
      clearTimeout(this.timeoutID);
      return;
    }

    try {
      clearTimeout(this.timeoutID);
      this.loadingText = 'Komunikujeme s platební bránou...';
      // TODO Refactor to not send all the unnecessary data
      if (reservation.accountedEntityCharge.paymentData?.client_secret) {
        await this.confirmPayment(reservation.accountedEntityCharge.paymentData.client_secret, reservation.id);
        this.triggerGtagEvent('reservation-successfull-payment', '', {
          value: reservation.overallPrice - (this.activeDiscount ? this.activeDiscount.priceDiscountedByAmount : 0),
          currency: 'CZK',
          coupon: this.activeDiscount ? this.activeDiscount.code : 'none'
        });
      } else {
        throw new Error('Client secret is not available to proceed with payment');
      }
    } catch (error) {
      this.handleConfirmPaymentError(error as Error | StripePaymentError);
    } finally {
      this.loadingText = 'Potvrzujeme rezervaci...';
      window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
      this.loading = false;
      this.$router.push({
        name: PaymentRoute,
        params: {
          reservationId: reservation.id
        }
      });
      this.toggleDisabledActive();
      this.$store.dispatch('setActiveDiscount', null);
    }
  }

  showReservationProcessBlockedBanner() {
    if (this.isReservationProcessBlocked) {
      this.$toast.error(this.reservationProcessBlockedText);
    }
  }
}

import axios from 'axios';

export interface AdditionalService {
  name: string;
  price: number;
  text: string;
}

class AdditionalServicesService {
  public async getAdditionalServices(): Promise<AdditionalService[]> {
    return (await axios.get('/additionalService')).data.data;
  }
}

export default new AdditionalServicesService();

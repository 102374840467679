import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import store from '@/store';

const CardPaymentMethod = 'card';
const BankTransferMethod = 'transfer';

export type PaymentMethodType = typeof CardPaymentMethod | typeof BankTransferMethod;

type FormValues = {
  paymentMethod: PaymentMethodType;
  termsAndConditionsAccepted: boolean;
  companyAgreementAccepted: boolean;
  rulesAccepted: boolean;
  isCompany: boolean;
  subscribesToNewsletter: boolean;
  additionalServices: Set<string>;
};

// moment.js calculates WHOLE days (24 hours)
const DAYS_BUFFER_FOR_BANK_TRANSFER = 4;

@Component({
  validations: {
    values: {
      // termsAndConditionsAccepted: {
      //   function(value) {
      //     return value === true;
      //   }
      // },
      companyAgreementAccepted: {
        function(value) {
          if (store.state.loggedUser?.user.isCompany) {
            return value === true;
          } else {
            return true;
          }
        }
      },
      rulesAccepted: {
        function(value) {
          return value === true;
        }
      }
    }
  }
})
export default class CheckoutStepThreeFormMixin extends Vue {
  public values: FormValues = {
    paymentMethod: CardPaymentMethod,
    termsAndConditionsAccepted: false,
    companyAgreementAccepted: false,
    rulesAccepted: false,
    isCompany: false,
    subscribesToNewsletter: false,
    additionalServices: new Set()
  };

  get isCompany(): boolean {
    return store.state.loggedUser?.user.isCompany || false;
  }

  get paymentCardMethodValue(): string {
    return CardPaymentMethod;
  }

  get bankTransferMethodValue(): string {
    return BankTransferMethod;
  }

  get isReservationSoon() {
    const from = this.$route.query.from.toString();
    const fromDate = moment(from).toDate();
    const dateDiff = moment(fromDate).diff(moment(), 'days');
    return dateDiff < DAYS_BUFFER_FOR_BANK_TRANSFER;
  }

  @Watch('values', { deep: true })
  valuesChange(): void {
    this.$v.$reset();
  }

  validate(): boolean {
    this.$v.$touch();
    return !this.$v.$invalid;
  }

  validationMessage(field: string): string | null {
    const validation = this.$v.values[field];
    if (!validation || !validation.$error) {
      return null;
    }

    switch (field) {
      case 'termsAndConditionsAccepted':
      case 'companyAgreementAccepted':
      case 'rulesAccepted':
        return 'Bez souhlasu s tímto bodem vám bohužel nemůžeme půjčit dodávku.';
    }

    return null;
  }
}
